import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Guillermo Arce | Software Engineer', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: 'Welcome to my portfolio!', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: "Hi, I'm ",
  name: 'Guillermo Arce',
  subtitle: 'Software engineer from Spain 🇪🇸',
  cta: 'Know more',
};

// ABOUT DATA
export const aboutData = {
  img: 'IMG_1653.jpg',
  paragraphOne1:
    'With a background in the banking sector, where I helped develop a 100% digital bank, I am currently working as a ',
  boldParagraphOne: 'software engineer',
  paragraphOne2: ' (backend) at the fintech unicorn Pleo.',

  paragraphTwo1: 'Ambitious and ',
  boldParagraphTwo: 'motivated by innovation',
  paragraphTwo2:
    ', I thrive in cutting-edge projects, drawing on my versatility and ability to learn quickly.',

  paragraphThree1: 'I prioritize clean, straightforward code that ',
  boldParagraphThree: 'balances quality with pragmatism. ',
  paragraphThree2:
    'My focus is on building flexible, high-performance systems that seamlessly adapt to fast-paced environments.',

  paragraphFour: 'These are some of the tech related topics that concern me most in my day-to-day:',
  topics: 'Software engineering, startups, serverless, clean code, fintech, etc.',
  resume: '', // if no resume, the button will not show up
};

// SKILLS DATA
export const skillsData = {
  img: 'wordcloud_skills.png',
};

// TRAINING DATA
export const trainingData = [

  {
    title: 'API Training for Engineers @ Skiller Whale',
    url: 'https://www.skillerwhale.com/',
    type: 'Training 🎯',
    trainingStatus: 'Ongoing',
  },
  {
    title: 'AWS Summit Madrid 2024',
    url: 'https://aws.amazon.com/es/events/summits/madrid/',
    type: 'Event 📌',
    trainingStatus: 'June 2024',
  },
  {
    title: 'Google Cloud Summit Madrid 2024',
    url: 'https://cloudonair.withgoogle.com/events/summit-spain-2024',
    type: 'Event 📌',
    trainingStatus: 'June 2024',
  },
  {
    title: 'Tetuan Valley Startup School 23 @ Google for Startups',
    url: 'https://www.tetuanvalley.com/startupschool',
    type: 'Startup Incubator 🔥',
    trainingStatus: 'November 2023',
  },
  {
    title: 'AWS Solutions Architect Associate Certification',
    url: 'https://www.credly.com/badges/3e8d8a4d-71d0-41d4-b06a-0b9b1b20e999/linked_in_profile',
    type: 'Certification 💡',
    trainingStatus: 'June 2023',
  },
  {
    title: 'AWS Summit Madrid 2023',
    url: 'https://aws.amazon.com/es/events/summits/madrid/',
    type: 'Event 📌',
    trainingStatus: 'June 2023',
  },
  {
    title: 'API First Day - APIAddicts',
    url: 'https://www.apiaddicts.org/api-first-day/',
    type: 'Event 📌',
    trainingStatus: 'May 2023',
  },
  {
    title: 'Codemotion - Madrid 2023',
    url: 'https://extra.codemotion.com/live-conference-madrid-2023/',
    type: 'Event 📌',
    trainingStatus: 'May 2023',
  },
  {
    title: 'Commit Conf - Madrid 2023',
    url: 'https://2023.commit-conf.com/',
    type: 'Event 📌',
    trainingStatus: 'April 2023',
  },
  {
    title: 'Open Commit Fest - First Edition',
    url: 'https://www.linkedin.com/posts/openbank_as%C3%AD-ha-sido-el-open-commit-fest-esta-ugcPost-7059922379015098369-vFJV/',
    type: 'Event 📌',
    trainingStatus: 'April 2023',
  },
  {
    title: 'Spring + Native + Kubernetes = Yes we can! - Madrid Java User Group',
    url: 'https://www.meetup.com/es-ES/madridjug/events/292274142/',
    type: 'Event 📌',
    trainingStatus: 'March 2023',
  },
  {
    title: 'Spring Boot 3 Overview - Madrid Java User Group',
    url: 'https://www.meetup.com/es-ES/madridjug/events/291176653/',
    type: 'Event 📌',
    trainingStatus: 'February 2023',
  },
  {
    title: 'Leading Change: Young entrepreneurs speak out on Energy Transition',
    url: 'https://openroom.fundacionrepsol.com/en/events/125/',
    type: 'Event 📌',
    trainingStatus: 'January 2023',
  },
  {
    title: 'JCON 2022 - International Java Community Conference',
    url: 'https://2022.jcon.one/',
    type: 'Event 📌',
    trainingStatus: 'September 2022',
  },
  {
    title: 'AWS Summit',
    url: 'https://aws.amazon.com/es/events/summits/madrid/',
    type: 'Event 📌',
    trainingStatus: 'May 2022',
  },
  {
    title: 'AWS GameDay',
    url: 'https://aws.amazon.com/gameday/',
    type: 'Competition 🎮',
    trainingStatus: 'March 2022',
  },
  {
    title: 'AWS Technical Essentials Day',
    url: 'https://aws.amazon.com/es/training/classroom/aws-technical-essentials/',
    type: 'Online Course ✏️',
    trainingStatus: 'March 2022',
  },
  {
    title: 'Building Modern Java Applications on AWS',
    url: 'https://www.edx.org/es/course/building-modern-java-applications-on-aws',
    type: 'Online Course ✏️',
    trainingStatus: 'February 2022',
  },
  {
    title: 'AWS Immersion Day',
    type: 'Online Course ✏️',
    trainingStatus: 'November 2021',
  },
];

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'cactian.png',
    title: 'Cactian',
    projectTags: '#Startup #Data #Personalization #AI',
    info: 'Cactian enables content platforms to quickly access and utilize high-quality user data, streamlining the activation process and enhancing retention from the first user interaction.',
    info2: '',
    url: 'https://cactian.com',
  },
  {
    id: nanoid(),
    img: 'project_stock_prediction.png',
    title: 'Stock Market Prediction - Analysis and Web app',
    projectTags: '#Python #Flask #REST #TensorFlow #PyTorch #AWS',
    info: 'As my degree dissertation, I analysed and applied TensorFlow and PyTorch from a SWE point of view. Both were applied to financial data in order to build a prototype of a real-time stock prediction system.',
    info2:
      'This project was awarded with honors in the Software Engineering degree of the University of Oviedo in 2021.',
    url: 'https://1drv.ms/b/s!Av3QMi1tzb4cdfgG987Wmr1D9Ok?e=yiKhUd',
    checkProject: 'Check Doc',
    repo: 'https://github.com/guillermoarce07/stock_market_webapp', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project_dlp.png',
    projectTags: '#Java #DesignPatterns #OOP #FOP #AST',
    title: 'Design of a Programming Language',
    info: 'This project was focused in the design and implementation of a programming language. However, there was a special focus on writing SOLID code as well as giving the importance to design that it actually deserves for a robust SWE project.',
    checkProject: 'Source code',
    url: 'https://gitfront.io/r/user-2473699/564ebf7d5b798bdab15c73fdc3cf44c3ab111c1f/Programming-Language-Design/',
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'You got a problem to solve?',
  btn: "Let's talk",
  email: 'arceguillermo@outlook.es',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/guillermo-arce-poyal/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/guillermo-arce',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
